.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.TopRow {
  flex: 0 0 auto;
  text-align: center;
}
.BottomRow {
  flex: 1 1 auto;
}

.Panel {
  display: flex;
  flex-direction: column;
}

.PanelContent {
  height: 100%;
  width: 100%;
  background-color: var(--color-panel-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5rem;
}

.ResizeHandleOuter {
  flex: 0 0 1.5em;
  position: relative;
  outline: none;

  --background-color: transparent;
}
.ResizeHandleOuter[data-resize-handle-active] {
  --background-color: var(--color-solid-resize-bar-handle);
}

.ResizeHandleInner {
  position: absolute;
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
  border-radius: 0.25em;
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
}

.Button {
  color: var(--color-default);
  background-color: var(--color-button-background);
  border: 1px solid var(--color-button-border);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.Button:hover {
  background-color: var(--color-button-background-hover);
}

.Link {
  display: block;
  color: var(--color-link);
  margin-bottom: 0.5rem;
}

.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}
