.one-hours-group span.name, .one-hours-group .select-display-time {
    background-color: #f7f7f7;
    width: 15%;
    padding: 5px;
    margin: 0;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #009da0;
}
.one-hours-group span.name {
    font-size: 20px;
}
.one-hours-group .select-display-time {
    display: flex;
    flex-direction: column;
    color: #666;
    background-color: #d5e2ea;
}
.one-hours-group .select-display-time div {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.one-hours-group .select-display-time div svg {
    margin-right: 10px;
    font-size: 25px;
}
.one-hours-group .select-display-time .eh-time-picker {
    margin: 0;
}
.one-hours-group .select-display-time .eh-time-picker .MuiInput-underline:before {
    border-bottom: none;
}
.one-hours-group .select-display-time .eh-time-picker #time-picker {
    width: 70px;
}
.one-hours-group .select-display-time .eh-time-picker div div button {
    padding: 0;
}
.one-hours-group .select-display-time .eh-time-picker div div button svg {
    margin: 0;
}
.one-hours-group .select-display-time .eh-time-picker.start div div button svg {
    color: #009da0;
}
.one-hours-group .select-display-time .eh-time-picker.end div div button svg {
    color: #e91e63;
}
.one-hours-group span.name {
    font-weight: 600;
}
.one-room-group span.name, .one-room-group .on-off-room {
    color: #009da0;
}
.one-room-group span.name span.name span.name, .one-room-group .on-off-room span.name span.name, .one-room-group span.name .on-off-room span.name, .one-room-group .on-off-room .on-off-room span.name, .one-room-group span.name span.name .on-off-room, .one-room-group .on-off-room span.name .on-off-room, .one-room-group span.name .on-off-room .on-off-room, .one-room-group .on-off-room .on-off-room .on-off-room {
    background-color: #f7f7f7;
    width: 14%;
    padding: 5px;
    margin: 0;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #009da0;
}
.one-room-group span.name span.name .on-off-room, .one-room-group .on-off-room span.name .on-off-room, .one-room-group span.name .on-off-room .on-off-room, .one-room-group .on-off-room .on-off-room .on-off-room {
    cursor: pointer;
}
.one-room-group span.name .ec-select.form-hospital-check-infos, .one-room-group .on-off-room .ec-select.form-hospital-check-infos {
    background-color: #666 !important;
    min-width: 240px;
    max-width: 240px;
}
.one-room-group span.name .number-studies .MuiTypography-body1, .one-room-group .on-off-room .number-studies .MuiTypography-body1 {
    font-size: 0.85rem;
    color: #009da0;
}
.one-room-group span.name .number-studies .MuiTypography-body1 span, .one-room-group .on-off-room .number-studies .MuiTypography-body1 span {
    font-weight: 600;
}
.one-room-group span.name .number-studies .MuiTypography-body1 span.error, .one-room-group .on-off-room .number-studies .MuiTypography-body1 span.error {
    color: red;
}
.one-room-group span.name .title-check-group, .one-room-group .on-off-room .title-check-group {
    font-weight: 500 !important;
    font-size: 18px !important;
}
.one-room-group .on-off-room {
    cursor: pointer;
}
.ec-select.form-hospital-check-infos {
    background-color: #666 !important;
    min-width: 240px;
    max-width: 240px;
}
.number-studies .MuiTypography-body1 {
    font-size: 0.85rem;
    color: #009da0;
}
.number-studies .MuiTypography-body1 span {
    font-weight: 600;
}
.number-studies .MuiTypography-body1 span.error {
    color: red;
}
.title-check-group {
    font-weight: 500 !important;
    font-size: 18px !important;
}
