.react-pdf__Document {
    background-color: #525659;
    border: 1px solid #525659;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    min-width: 60vh;
    padding: 10px;
}
.react-pdf__Page {
    -webkit-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
    background-color: #fff;
}
