.ec-box-auto {
  background-color: rgba(0, 157, 160, 0.1);
  width: 100%;
  padding-left: 5px !important;
  border-radius: 2px;
}
.dialog-file-input .button-upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.dialog-file-input .button-upload i {
  color: white;
  font-size: 21px;
  width: 40px;
  height: 40px;
  background-color: #009da0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.dcai-radio-button .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
}
.dcai-radio-button p {
  font-weight: 500;
  font-size: 18px;
}
