.input-files {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.input-files .item-base {
    width: 100px;
    height: 100px;
    padding: 0px;
    margin-right: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem !important;
    border-left-width: 1px !important;
    position: relative;
    line-height: initial;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.input-files .item-file {
    border-width: 2px !important;
    border-style: dashed !important;
    border-color: #dadada;
}
.input-files .item-file .item-actions {
    position: absolute;
    bottom: -32px;
    width: 104%;
}
.input-files .item-input {
    border-width: 2px !important;
    border-style: dashed !important;
    color: #dadada;
    cursor: pointer;
}
.input-files .item-input.over {
    border-color: #009da0;
}
.input-files .item-input input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
