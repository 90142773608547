@import './footer.css';
@import './form.css';
@import './input-file.css';
@import './input.css';
@import './pdf.css';
@import './loading.css';

@media (min-width: 1150px) {
  .MuiContainer-maxWidthLg {
    max-width: 1150px !important;
  }
}

/* // scroll bar */
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 20px;
  /* // outline: 1px solid slategrey; */
}
