@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body,
#root,
.App {
  background-color: #e2e6f2;
  height: 100%;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: inherit;
}
.MuiDataGrid-row:nth-child(odd) {
  background-color: #fff;
}
.MuiDataGrid-row:nth-child(even) {
  background-color: #eceff7;
}
/* .css-1pans1z-MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
} */
.slide-in-form {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background: white;
  z-index: 1300;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.slide-in-form.open {
  transform: translateX(0);
}
